import React, { useState, Suspense } from "react";
import TeamCard from "./teamCard";


function TeamMobile({ data }) {
    const intersecting = [useState({
        isIntersecting: false,
        setIntersecting: false
    })];

    for (var i = 0; i < data.members.length; ++i) {
        intersecting[i] = ({
            isIntersecting: false,
            setIntersecting: false
        });
    }

    return (
        <div className="text-center m-auto full-width mt-8">
            <div className="container-margin">
                <p className="uppercase title-white-border" >{data.title}</p>

                <div className="container-grid-team mt-4" id="container-team">
                    {data.members.map((item, key) => (
                        <TeamCard item={item} intersecting={intersecting} key={key}  mobile/>
                    ))}
                </div>
            </div>
        </div >
    );
}
export default TeamMobile;