import React, { useState, Suspense } from "react";
import { InView } from "react-intersection-observer";


function TeamCard({ item, intersecting, number, mobile }) {

    function update(name, key) {
        if (name !== undefined && typeof document !== `undefined`) {
            var elem = document.getElementById(name);
            if (intersecting[key].isIntersecting) {
                setTimeout(function () {
                    elem.classList.add("team-col-hover")
                }, 500);
            } else if (!intersecting[key].isIntersecting) {
                setTimeout(function () {
                    elem.classList.remove("team-col-hover")
                }, -500);
            }
        }
    }

    if (mobile)
        return (
            <InView as="div" key={number + item.name} className="team-col-mobile" rootMargin="-40% 0% -40% 0%" onChange={inView => {
                intersecting[number].isIntersecting = inView
                intersecting[number].setIntersecting = inView
                update(item.name, number)
            }}>
                <div id={item.name} className="team-col m-auto text-right">
                    <div className="bg-blue-rotate mb-1">
                        <div className="container-images">
                            <img src={item.img} alt={item.alt} className="bottom" />
                            <img src={item.imgHover} alt={item.alt} className="top" />
                        </div>
                    </div>
                    <div>
                        <p className="ISemiBold blue uppercase mt-3 info-large">{item.name}</p>
                        <hr className="half-opacity" />
                        <p className="ISemiBold white uppercase info-large">{item.job}</p>
                    </div>
                </div>
            </InView>)
    else
        return (
            <InView as="div" key={item.name} className="team-col m-auto text-right" rootMargin="-50% 0% -50% 0%" onChange={inView => {
                intersecting[number].isIntersecting = inView
                intersecting[number].setIntersecting = inView
                update(item.name, number)
            }}>
                <div className="team-col m-auto text-right">
                    <div id={item.name} className="team-col m-auto text-right">
                        <div className="bg-blue-rotate mb-1">
                            <div className="container-images">
                                <img src={item.img} alt={item.alt} className="bottom" />
                                <img src={item.imgHover} alt={item.alt} className="top" />
                            </div>
                        </div>
                        <div className="second-row">
                            <p className="ISemiBold blue uppercase fs-6">{item.name}</p>
                            <hr className="half-opacity" />
                            <p className="ISemiBold white uppercase fs-6">{item.job}</p>
                        </div>
                    </div>
                </div>
            </InView>
        );
}
export default TeamCard;
