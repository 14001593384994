import React, { useContext } from "react";
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import { useI18next } from 'gatsby-plugin-react-i18next'
import '../../sass/test.scss';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, EffectCards } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import "swiper/css/effect-cards";

import { getPublicPath } from '../../custom/functions'
import GeneralButton from "../generalButton";

const EffectSlideCards = ({ data, publicsUrl, outSrc }) => {

    const { language } = useI18next()

    const {
      translateUrl // (url(, locale)) => translatedUrl
    } = useContext(TranslateUrlsContext)

    return (
        <div className="container-slogan-scroll-trigger-mobile">
            <div className="container-swiper p-relative projects-highlight">
                <Swiper
                    scrollbar={{
                        hide: false,
                    }}
                    effect={"cards"}
                    grabCursor={true}
                    className="mySwiper"
                    initialSlide={0}
                    loop={true}
                    direction='horizontal'
                    modules={[EffectCards, Navigation, Pagination, Scrollbar]}
                    pagination={{
                        clickable: true
                    }}
                    navigation={{ nextEl: ".js-nav-next", prevEl: ".js-nav-prev", enabled: true }}
                >
                    {data.projetos.map((project, index) => (
                        <SwiperSlide key={'project-' + project.name + index}>
                            <div style={{ backgroundImage: `url('${outSrc ?  project.src : getPublicPath(publicsUrl, project.src)}')` }} className="bg-image"></div>
                            <div className="container-slider">
                                <div className="capsule-slider">
                                    <p className="uppercase white fs-4 IBold swiper-title">{project.name}</p>
                                    {project.btntext &&
                                        <GeneralButton text={project.btntext} url={translateUrl(project.btnlink,  language)} center={true} />
                                    }

                                </div>
                            </div>
                        </SwiperSlide>
                    ))}

                    <div className="ui-element -bottom-right sm:d-none js-ui">
                        <div className="js-slider-main-nav">
                            <button   aria-label="Mute volume" type="button" className="navButton__item -outline-white js-nav-prev text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left icon"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                            </button>

                            <button  aria-label="Mute volume" type="button" className="navButton__item -outline-white js-nav-next text-white">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-right icon"><line x1="5" y1="12" x2="19" y2="12"></line><polyline points="12 5 19 12 12 19"></polyline></svg>
                            </button>
                        </div>
                    </div>
                </Swiper>
            </div>

        </div >
    );
}


export default EffectSlideCards
