import React, { useState, Suspense } from "react";
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { InView } from "react-intersection-observer";
import TeamCard from "./teamCard";


function Team({ data }) {
    console.log(data.members.length)
    const intersecting = [useState({
        isIntersecting: false,
        setIntersecting: false
    })];

    for (var i = 0; i < data.members.length; ++i) {
        intersecting[i] = ({
            isIntersecting: false,
            setIntersecting: false
        });
    }


    const n = 3 //tweak this to add more items per line

    function dividirArray(array, tamanhoSubarray) {
        const resultado = [];
        let indice = 0;

        while (indice < array.length) {
            resultado.push(array.slice(indice, indice + tamanhoSubarray));
            indice += tamanhoSubarray;
        }

        return resultado;
    }

    const result = dividirArray(data.members, n);
    console.log(result)

    return (
        <div className="text-center m-auto full-width ">
            <div className="container-margin">
                <p className="uppercase title-white-border mb-5 pb-5" >{data.title}</p>

                <div className="container-grid-team" id="container-team">
                    {result.map((item, key) => (
                        <>
                            {item[0] &&
                                <TeamCard item={item[0]} intersecting={intersecting} number={key} />
                            }
                            {item[2] &&
                                <TeamCard item={item[2]} intersecting={intersecting} number={key} />
                            }
                            {item[1] &&
                                <TeamCard item={item[1]} intersecting={intersecting} number={key} />
                            }
                        </>
                    ))}
                </div>
            </div>
        </div >
    );
}
export default Team;
